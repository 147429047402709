// plat_dom_getAllProductType

const __request = require(`./__request/flight-admin/__request_contentType_json`);

// 接口地址： http://192.168.0.172:8985/doc.html#/yinzhilv-flight/%E5%B9%B3%E5%8F%B0-%E7%BA%BF%E4%B8%8B-%E8%AE%A2%E5%8D%95%E6%8E%A5%E5%8F%A3/getAllProductTypeUsingPOST

export default function plat_dom_getAllProductType(data) {
    let params = {}
    params.method = 'POST'
    params.urlSuffix = '/plat/dom/getAllProductType'
    params.data = data
    return new Promise(function (resolve, reject) {
        resolve(__request(params))
    })
}
