// plat_dom_getPartProductType
const __request = require(`./__request/flight-admin/__request_contentType_json`);

export default function plat_dom_getPartProductType(data) {
    let params = {}
    params.method = 'POST'
    params.urlSuffix = '/plat/dom/getPartProductType'
    params.data = data
    return new Promise(function (resolve, reject) {
        resolve(__request(params))
    })
}
