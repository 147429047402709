import _ from 'underscore';
import moment from 'moment';

import flight_domestic_buyer_manage_order_refList from "@/lib/data-service/flight/newFligthApi/buyer_dom_manage_reimburseList"
import reimburseCount from "@/lib/data-service/flight/newFligthApi/buyer_dom_manage_reimburseCount";
import reimburseRemark from "@/lib/data-service/flight/newFligthApi/buyer_dom_manage_reimburseRemark";

import flight_domestic_airlines from '@/lib/data-service/flight/flight_domestic_airlines'
import order_cancel from '@/lib/data-service/flight/flight_domestic_buyer_white_order_cancel'
import scrollTop from '@/lib/common-service/scrollTop'
// import FligthTime from '@/page/distributor/air-ticket/admin/component/flightTimeBox.vue'
import AirlineSelect from '@/page/distributor/air-ticket/components/airlineSelect.vue'
import plat_dom_getAllProductType from '@/lib/data-service/flight/plat_dom_getAllProductType'
import { awaitWrap } from "@/page/distributor/train/front/common/unit";
import RefundChangeLabel from '@/page/distributor/air-ticket/admin/component/refund-change-label/index.vue'
import plat_dom_getPartProductType from '@/lib/data-service/flight/plat_dom_getPartProductType'
export default {
  components: { AirlineSelect, RefundChangeLabel },
  data() {
    return {
      //timeUpbox: null,
      //timeUpboxHeight: null,
      searchForm: {
        pnr: ``,
        orderNo: ``,
        ticketNumber: ``,
        airCode: ``,
        psgName: ``,
        productType: '',
        appTimeBegin: '',
        appTimeEnd: '',
        appTime: [],
      },
      cancelOrderNo: '',
      dialogVisible: false,
      pageResult: {
        currentPage: 1,
        pageSize: 10,
        pageData: [],
        totalCount: 0,
      },
      pCurrentPage: 1,
      data_flight_domestic_airlines: {
        pageResult: {
          pageData: [],
        }
      },

      data_flight_domestic_buyer_manage_order_refStates: {
        pageResult: {
          pageData: [],
        }
      },
      refundRecordCountList: [],
      loading: true,
      NavItemActive: 0,
      orderRemark: {},
      remark: '',

      productTypeList: [],
      selectProductTypeList: [
        /*{ label: '全部', value: '' },
        { label: '平台优选', value: '1' },
        { label: '官网', value: '2' },
        { label: '低价推荐', value: '3' }*/
      ],
    };
  },
  computed: {
    orderTimeStart: {
      get() {
        const __this = this;
        const flag = moment(__this.searchForm.__orderTimeStart).isValid();
        let s = ``;
        if (flag) {
          const d = moment(__this.searchForm.__orderTimeStart);
          s = d.format(`YYYY/MM/DD`);
        }
        return s;
      }
    },
    orderTimeEnd: {
      get() {
        const __this = this;
        const flag = moment(__this.searchForm.__orderTimeEnd).isValid();
        let s = ``;
        if (flag) {
          const d = moment(__this.searchForm.__orderTimeEnd);
          s = d.format(`YYYY/MM/DD`);
        }
        return s;
      }
    },
  },
  methods: {
    getPartProductType() {
      plat_dom_getPartProductType().then(res => {
        let selectProductTypeList = res.producyTypes
        selectProductTypeList.unshift({
          code: '',
          msg: '全部'
        })
        this.selectProductTypeList = selectProductTypeList
      })
    },
    async __data_bind_list(pParameter) {
      const __this = this;
      if (!pParameter) pParameter = {};
      let data = Object.assign({}, this.searchForm, this.pageResult)
      __this.loading = true;
      flight_domestic_buyer_manage_order_refList(data).then(res => {
        __this.pageResult.pageData = res.pageResult.pageData || [];
        __this.pageResult.totalCount = res.pageResult.totalCount;
        __this.loading = false;
        this._reimburseCount(data)
        scrollTop(500)
      }).catch(error => {
        __this.pageResult.pageData = []
        __this.pageResult.totalCount = 0
        __this.loading = false;
      })
    },

    __el_pagination_current_change_event_handler(pCurrentPage) {
      const __this = this;
      __this.pageResult.currentPage = pCurrentPage
      __this.__data_bind_list();
    },



    __el_pagination_size_change_event_handler(pPageSize) {
      const __this = this;
      __this.pageResult.pageSize = pPageSize
      __this.__data_bind_list();
    },
    __changeAppTime(val) {
      const __this = this;
      if (val === '' || val === null || val.length === 0) {
        __this.searchForm.appTimeBegin = ''
        __this.searchForm.appTimeEnd = ''
      } else {
        __this.searchForm.appTimeBegin = val[0] + ' 00:00:00'
        __this.searchForm.appTimeEnd = val[1] + ' 23:59:59'
      }
      __this.__btn_search_click()
    },
    __btn_search_click(val) {
      const __this = this;
      __this.__data_bind_list({ currentPage: 1 });
    },

    __btn_reset_click() {
      const __this = this;

      _.each(__this.searchForm, function (property_value, property_name) {
        __this.searchForm[property_name] = ``;
      });
      __this.searchForm.reiStatus = ``;

      __this.__data_bind_list();
    },

    __btn_tab_click(pItem, pIndex) {
      const __this = this;
      pItem.status == 1 ? __this.searchForm.reiStatus = '' : __this.searchForm.reiStatus = pItem.status;
      __this.NavItemActive = pIndex;
      this.pageResult.currentPage = 1
      __this.__btn_search_click();
    },
    _reimburseCount(data) {
      reimburseCount(data).then(res => {
        this.refundRecordCountList = res.records
      })
    },
    _remark(val) {
      if (!this.remark) return this.$message({ type: 'warning', message: '请输入内容' })
      let data = {
        currentPage: '',
        pageSize: '',
        reiOrderNo: val.reiOrderNo,
        remark: this.remark
      }
      reimburseRemark(data).then(res => {
        if (res.code == this.SUCCESS_CODE) {
          this.dialogVisible = false;
          this.remark = ''
          this.$message({ type: 'success', message: '操作成功！' })
        }
      })
    },
    // offHeight(e) {
    //   this.timeUpboxHeight = e.target.offsetParent.offsetHeight
    //
    //   // this.timeUpboxHeight = e.fromElement.localName == 'td' ? e.fromElement.offsetHeight : 0
    // },
    async getAllProductType() {
      let [err, res] = await awaitWrap(plat_dom_getAllProductType())
      console.log(res)
      if (err) {
        return
      }
      let productTypeList = res.producyTypes
      productTypeList.unshift({
        code: '',
        msg: '全部'
      })
      this.productTypeList = productTypeList
    },
    setTagType(val) {
      let type = ''
      const typeList = ['policy-label-1', 'policy-label-2', 'policy-label-3', 'policy-label-4', 'policy-label-5', 'policy-label-6']
      this.productTypeList.forEach((value, index) => {
        const codeList = value.code.split(',')
        if (codeList.indexOf(val) >= 0) {
          type = typeList[index]
        }
      })
      return type
    },
  },
  watch: {
    '$route.query.ordersNo': function (newId) {
      this.__data_bind_list()
    }
  },
  async mounted() {
    const __this = this;
    const data_flight_domestic_airlines = await flight_domestic_airlines();
    __this.data_flight_domestic_airlines = data_flight_domestic_airlines;
    this.getPartProductType()
    await this.getAllProductType();
    __this.__data_bind_list();
  },
}
