const __request = require(`./__request/__request_contentType_json`);

// 取消订单
// 接口文档地址：http://183.58.24.99:9999/swagger-ui.html?tdsourcetag=s_pcqq_aiomsg#/
const refund = (pParameter) => {
  if (!pParameter) pParameter = {};
  const orderNo = pParameter.orderNo || '';
  const remark = pParameter.remark || '';
  pParameter = {
    method: 'post',
    // urlSuffix: '/flight/domestic/buyer/white/order/cancel',
    urlSuffix:'/buyer/dom/white/cancel',
    data: {
      orderNo, remark
    }
  }
  return __request(pParameter)
}
module.exports = refund;
